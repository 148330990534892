import { createApp } from 'vue'
import App from './App.vue'

import 'animate.css/animate.min.css'

const app = createApp(App)
import router from './router'
app.use(router)

import TDesign from 'tdesign-mobile-vue';
import 'tdesign-mobile-vue/es/style/index.css';
app.use(TDesign)

import './ui24.css'

import config from '@/config'
app.config.globalProperties.$CONFIG = config;

import http from '@/utils/http'
app.config.globalProperties.$HTTP = http;

//挂载app
app.mount('#app')
