<template>
  <router-view v-slot="{ Component }">
    <transition name="ranimate">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<style lang="less">
.ranimate-enter-active {
  animation: slideInRight 1s
}
.ranimate-leave-active {
  animation: slideOutLeft 0.3s
}
</style>
