export default {
  tokenName: `TOKEN`,
  request: {
    production: {
      protocol: "https",
      sld: "scrm.api"
    },
    development: {
      protocol: `http`,
      sld: `scrmdev.api`
    },
    tld: `moxiaohong.com`
  }
}