import axios from 'axios'
import qs from 'qs'
import {Toast} from 'tdesign-mobile-vue'
import sysConfig from '@/config'

class http {
  constructor() {
    this.requestCounter = 0 // 请求计数器
  }

  destroy (option) {
		if (option.loading === false) {
			return
		}
    --this.requestCounter == 0 &&  Toast.clear()
  }

  showLoading(option) {
		if (option.loading === false) {
			return
		}
    const toastConfig = {theme: `loading`, duration: 0, message: `加载中`}
    if (option.method == `POST`) {
      toastConfig.message = `保存中`
      toastConfig.preventScrollThrough = true
    }
    toastConfig.message = option.loadingText === undefined ? toastConfig.message : option.loadingText
    this.requestCounter++
    Toast(toastConfig)
  }

  createOption(option) {
    option = Object.assign({method: `GET`, headers: {}}, option)
    const node = sysConfig.request[process.env.NODE_ENV]
    option.baseURL = `${node.protocol}://${node.sld}.${sysConfig.request.tld}`
    if (option.method === `POST`) {
      option.headers['Content-Type'] = `application/x-www-form-urlencoded`
      option.data = qs.stringify(option.data)
    } else {
			option.params = option.params === undefined ? {} : option.params
		}
    return option
  }

  request(option) {
    option = this.createOption(option)

    this.showLoading(option)

    axios(option).then((res) => {
      // 关闭loading
      this.destroy(option)
      
      const code = res.data.code
      const msg = res.data.msg
      const data= res.data.data
      let done = () => {}

      switch(code) {
  			case 0:
  			done = option.error === undefined ? () => {} : option.error
        break
        
  			case 1:
  			done = option.success === undefined ? () => {} : option.success
  			break
      }

      if (msg == "") {
        done(data)
      } else {
        Toast({
          theme: code == 1 ? `success` : `error`,
          message: msg,
          onClose: () => {
            done(data)
          }
        })
      }

    }).catch(error => {
			this.destroy(option)
			Toast({message: error.message})
		})
  }
}


export default new http