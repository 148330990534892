import {isWx, wxAuthorize} from '@/utils/tool'
import {Toast} from 'tdesign-mobile-vue'
export default [
  {
    path: '/bind',
    name: '绑定服务号',
    component: () => import('../views/bind'),
    beforeEnter: (to, from, next)=> {
			if (to.query.code === undefined) {
				wxAuthorize('/bind')
			} else {
				next()
			}
    }
  },
	{
		path: '/orderPay/:id',
		name: '订单支付',
		component: ()=> import('../views/order-pay'),
		beforeEnter: (to, from, next)=> {
			if (isWx() && to.query.code === undefined) {
				wxAuthorize(`/orderPay/${to.params.id}`)
			} else {
				next()
			}
		}	
	},
	{
		path: '/live/:id',
		name: '直播',
		component: ()=> import('../views/live-detail'),
		beforeEnter: (to, from, next)=> {
			if (isWx() === false) {
				next('/env')
			} else {
				if (to.query.code === undefined) {
					wxAuthorize(`/live/${to.params.id}`, 'snsapi_userinfo')
				} else {
					next()
				}
			}
		}
	},
	{
		path: '/env',
		name: '微信环境检测结果',
		component: ()=> import('../views/env')
	}
]